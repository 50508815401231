import { createSlice } from '@reduxjs/toolkit';
const axios = require('axios');

export const errorSlice = createSlice({
  name: 'error',
  initialState: false,
  reducers: {
    setError: (state, action) => action.payload,
    clearError: () => false,
  },
});

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: false,
  reducers: {
    toggleLoading: (state) => !state,
    setLoading: (state, action) => action.payload,
  },
});

export const helperDataSlice = createSlice({
  name: 'helperData',
  initialState: { score: null },
  reducers: {
    addState: (state, action) => (state = { ...state, ...action.payload }),
    setNewState: (state, action) => (state = action.payload),
  },
});

export const changeStateSlice = createSlice({
  name: 'changeState',
  initialState: { sidebarShow: 'responsive' },
  reducers: {
    changeStateReducer: (state, action) => (state = action.payload),
  },
});
