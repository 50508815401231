import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { journalSlice } from './reducers/journalSlice';
import { errorSlice, loadingSlice, helperDataSlice, changeStateSlice } from './reducers/additionalSlice';
import { strategySlice } from './reducers/stratergySlice';
import { updateProgressSlice } from './reducers/updateProgressSlice';
import { goalsSlice } from './reducers/goalsSlice';
import logger from 'redux-logger';

const reducer = {
  error: errorSlice.reducer,
  loading: loadingSlice.reducer,
  journal: journalSlice.reducer,
  strategy: strategySlice.reducer,
  updateProgress: updateProgressSlice.reducer,
  helperDataSlice: helperDataSlice.reducer,
  goals: goalsSlice.reducer,
  changeState: changeStateSlice.reducer,
};

const middleware = [...getDefaultMiddleware()];

const store = configureStore({
  reducer,
  middleware,
});

export default store;
