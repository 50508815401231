import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter, Route, Switch, HashRouter, Redirect, useLocation } from 'react-router-dom';
import { PrivateRoute, Loading, AddPayload, auth as auth_ } from './utils';
import toast, { Toaster } from 'react-hot-toast';
import './scss/style.scss';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Index = React.lazy(() => import('./views/pages/index/Index'));
//Authentication
const Home = React.lazy(() => import('./views/Home/Home'));
const Auth = React.lazy(() => import('./views/Auth/Auth'));
const Rdt = React.lazy(() => import('./Redirect'));

function App() {
  const [auth, setAuth] = useState(() => auth_);
  const [reRender, setReRender] = useState('');

  useEffect(() => {
    auth.combinedLoading(auth).then((auth) => {
      setAuth(auth);
    });
  }, [reRender]);

  return (
    <>
      <Toaster position="top-right" />
      <HashRouter>
        <React.Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route exact path="/redirect" render={(props) => <Rdt {...props} auth={auth} setAuth={setAuth} />} />
            <AddPayload path="/" exact payload={auth.signUpSchema} auth={auth} setAuth={setAuth}>
              {auth.isAuthenticated && <Redirect to={location.state?.from.pathname || '/dashboard'} />}
              {auth.isAuthenticated == false && auth.errorCode == 120 && <Redirect to="/auth/profile" />}
              {auth.isAuthenticated == false && auth.errorCode == 124 && <Redirect to="/auth/email_verification" />}
              {auth.isAuthenticated == false && <AddPayload path="/" exact payload={auth.signUpSchema} auth={auth} setAuth={setAuth} component={Index} />}
              {auth.isAuthenticated == null && <Loading />}
            </AddPayload>
            <AddPayload path="/privacy-policy" exact payload={auth.signUpSchema} auth={auth} setAuth={setAuth}>
              {auth.isAuthenticated == false && <AddPayload path="/privacy-policy" exact payload={auth.signUpSchema} auth={auth} setAuth={setAuth} component={Index} />}
              {auth.isAuthenticated == null && <Loading />}
            </AddPayload>
            <AddPayload path="/terms-and-conditions" exact payload={auth.signUpSchema} auth={auth} setAuth={setAuth}>
              {auth.isAuthenticated == false && (
                <AddPayload path="/terms-and-conditions" exact payload={auth.signUpSchema} auth={auth} setAuth={setAuth} component={Index} />
              )}
              {auth.isAuthenticated == null && <Loading />}
            </AddPayload>
            <Route
              path="/auth/logout"
              render={(props) => {
                localStorage.clear();
                setReRender('change');
                return <Redirect to="/" />;
              }}
            />
            <PrivateRoute path="/dashboard" payload={auth} auth={auth} setAuth={setAuth} component={TheLayout} />
            <AddPayload path="/auth" payload={auth.signUpSchema} auth={auth} setAuth={setAuth} component={Auth} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </>
  );
}

export default App;
