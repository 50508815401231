import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter, Route, Switch, HashRouter, Redirect, useLocation } from 'react-router-dom';
import { HalfCircleSpinner, TrinityRingsSpinner, AtomSpinner, ScalingSquaresSpinner, OrbitSpinner, SemipolarSpinner } from 'react-epic-spinners';
import TopAlertBar from './TopAlertBar.js';
const axios = require('axios');

export const Loading = () => (
  <SemipolarSpinner color="blue" style={{ position: 'absolute', margin: 'auto', top: '0', right: '0', bottom: '0', left: '0', width: '60px', height: '60px' }} />
);

export const backend_url = 'https://edserio.in';
// export const backend_url = 'http://localhost:5000';
// export const backend_url = window.location.origin;

export const Alert = TopAlertBar;

export const headers = () => ({ Authorization: 'JWT ' + window.localStorage.getItem('token') });

export const hitUrl = async (url, method, data, headers = false) => {
  // if (window.localStorage.getItem('token') == null && headers) {
  //   return { status: false, message: 'No Headers Present' };
  // }
  if (!window.navigator.onLine) {
    return { status: false, message: 'You are offline, please check your internet connection' };
  }
  var response = {};
  try {
    response = (
      await axios({
        method: method,
        url: backend_url + url,
        data: data,
        headers: headers ? { Authorization: 'JWT ' + window.localStorage.getItem('token') } : {},
      })
    ).data;
  } catch (err) {
    console.log(err);
    response = { status: false, message: 'Unexpected Error Occured' };
  }

  return response;
};

export const auth = {
  isAuthenticated: null,
  signUpSchema: null,
  errorCode: null,
  user: null,
  async authenticate(auth) {
    var newAuth = { ...auth };
    const response = await hitUrl('/isLoginSignUpSchema', 'get', null, true);
    newAuth.isAuthenticated = response.isLogin.status;
    newAuth.errorCode = response.isLogin.errorCode;
    newAuth.user = response.isLogin.status ? response.isLogin.user : null;
    return newAuth;
  },
  async loadSignUpSchema(auth) {
    var newAuth = { ...auth };
    const response = await hitUrl('/signUpSchema', 'get', null, false);
    newAuth.signUpSchema = response.status ? { config: response } : null;
    return newAuth;
  },
  async combinedLoading(auth) {
    var newAuth = { ...auth };
    const response = (
      await axios({
        method: 'get',
        url: backend_url + '/isLoginSignUpSchema',
        data: null,
        headers: {
          Authorization: 'JWT ' + window.localStorage.getItem('token'),
        },
      })
    ).data;
    newAuth.isAuthenticated = response.isLogin.status;
    newAuth.errorCode = response.isLogin.errorCode;
    newAuth.signUpSchema = response.config ? { config: response.config } : null;
    newAuth.user = response.isLogin.status ? response.isLogin.user : null;
    return newAuth;
  },
  signOut(auth) {
    var newAuth = { ...auth };
    newAuth.isAuthenticated = false;
    newAuth.errorCode = null;
    newAuth.user = null;
    localStorage.removeItem('token');
    return newAuth;
  },
};

export const PrivateRoute = ({ component: Component, auth, setAuth, ...rest }) => (
  <Route
    {...rest}
    render={({ location, ...props }) => {
      if (auth.isAuthenticated == true || (auth.errorCode == rest.allowErrorCode && auth.errorCode != null)) {
        return <Component {...props} auth={auth} setAuth={setAuth} />;
      } else if (auth.isAuthenticated == null) {
        return <Loading />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        );
      }
    }}
  />
);

// export const PrivateRoute = ({ component: Component, auth, ...rest }) => {
//   <Route
//     {...rest}
//     render={({ location, ...props }) => {
//       if (auth.isAuthenticated == true || auth.errorCode == rest.allowErrorCode) {
//         return <Component {...props} />;
//       } else if (auth.isAuthenticated == null) {
//         return <Loading />;
//       }
//     }}
//   >
//     {auth.isAuthenticated == false && <Redirect to={{ pathname: '/', state: { from: location } }} />}
//   </Route>;
// };

export const AddPayload = ({ component: Component, auth, payload, setAuth, ...rest }) => (
  <Route {...rest} render={(props) => (payload != null ? <Component {...props} payload={payload} auth={auth} setAuth={setAuth} /> : <Loading />)} />
);

export const PrivatePayload = ({ component: Component, auth, payload, setAuth, ...rest }) => (
  <Route
    {...rest}
    render={({ location, ...props }) => {
      if (payload != null && (auth.isAuthenticated == true || auth.errorCode == rest.allowErrorCode)) {
        return <Component {...props} payload={payload} auth={auth} setAuth={setAuth} />;
      } else if (auth.isAuthenticated == null || payload == null) {
        return <Loading />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        );
      }
    }}
  />
);

export const AdminRoute = ({ component: Component, auth, setAuth, ...rest }) => (
  <Route
    {...rest}
    render={({ location, ...props }) => {
      if (auth.isAuthenticated == true) {
        if (auth.user.accessType == 1) {
          return <Component {...props} auth={auth} setAuth={setAuth} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }
      } else if (auth.isAuthenticated == null) {
        return <Loading />;
      } else {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        );
      }
    }}
  />
);
