import urls from '../fetchUrls';
import { errorSlice, loadingSlice } from './additionalSlice';
import { hitUrl } from '../../utils';
import { backend_url } from './additionalSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const preReqs = (name) => {
  var thunks = {};
  Object.entries(urls[name]).map(([routeName, value]) => (thunks[`${routeName}${name.charAt(0).toUpperCase() + name.slice(1)}`] = thunk(routeName, name)));
  return {
    name: name,
    initialState: {
      [name]: null,
      isLoadedValues: false,
      currentNode: null,
    },
    thunks: thunks,
  };
};

const thunk = (routeName, name) =>
  createAsyncThunk(`${name}/${routeName}${name.charAt(0).toUpperCase() + name.slice(1)}`, async (action = {}, thunkAPI) => {
    thunkAPI.dispatch(errorSlice.actions.clearError());
    thunkAPI.dispatch(loadingSlice.actions.toggleLoading());
    const state = thunkAPI.getState()[name];
    if (routeName == 'loadInitialState' && state.isLoadedValues) {
      action.status = false;
      thunkAPI.dispatch(loadingSlice.actions.toggleLoading());
      return action;
    }
    try {
      const response = await hitUrl(urls[name][routeName].url, urls[name][routeName].method, action, true);
      action.status = response.status;
      action.data = response.data;
    } catch (err) {
      console.log(err);
      action.status = 0;
    }
    thunkAPI.dispatch(loadingSlice.actions.toggleLoading());
    if (!action.status) {
      action.status = false;
      thunkAPI.dispatch(errorSlice.actions.setError(urls[name][routeName].error));
    }
    return action;
  });
