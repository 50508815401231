import { makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

const useStyle = makeStyles((theme) => ({
  snackClass: {
    zIndex: '100',
    margin: '20px 0px ',
    maxWidth: '700px',
  },
  alertClass: {
    margin: '10px',
  },
}));

function TopAlertBar({ message, alert, close }) {
  const classes = useStyle();
  const alertArray = ['error', 'success', 'warning'];
  return (
    <>
      {message != null && (
        <Snackbar
          className={classes.snackClass}
          open={message != null}
          autoHideDuration={3000}
          onClose={close}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Alert variant="filled" onClose={close} severity={alertArray[alert]}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default TopAlertBar;
