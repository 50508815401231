import { createSlice } from '@reduxjs/toolkit';
import { preReqs } from './assistReducers';
import moment from 'moment';
const { name, initialState, thunks } = preReqs('journal');
export const { addEditJournal, loadInitialStateJournal } = thunks;

export const journalSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setCurrentNode: (state, action) => void (state.currentNode = action.payload),
  },
  extraReducers: {
    [loadInitialStateJournal.fulfilled]: (state, action) => {
      action = action.payload;
      if (action.status) {
        state[name] = action.data;
        state.isLoadedValues = true;
      }
    },

    [addEditJournal.fulfilled]: (state, { payload }) => {
      const { status, data, ...rest } = payload;

      if (status) {
        if (data.isRemoved == 1) {
          delete state.journal[rest.j_id];
        } else {
          state[name][data.j_id] = { ...rest, j_id: data.j_id, dateTime: moment(data.createdAt).format('Do MMM YYYY') };
          state.currentNode = null;
        }
      }
    },
  },
});

export const getThings = (state) => {
  if (!state[name].isLoadedValues) {
    return { state: state[name], currentNode: null, loading: state.loading, error: state.error };
  }
  return { state: state[name], currentNode: state[name].currentNode, loading: state.loading, error: state.error };
};
