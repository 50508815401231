export default {
  strategy: {
    addEdit: { url: '/strategy/createStrategy', method: 'post', error: 'Error in Saving Strategy' },
    loadInitialState: { url: '/strategy/getStrategy', method: 'get', error: 'Error in Loading Strategy' },
  },
  journal: {
    addEdit: { url: '/journal/createOrUpdate', method: 'post', error: 'Error in Saving Journal' },
    loadInitialState: { url: '/journal/all', method: 'get', error: 'Error in Loading Journals' },
  },
  updateProgress: {
    loadInitialState: { url: '/userStrategy/getAll', method: 'get', error: 'Error in Loading Progress' },
    saveProgress: { url: '/userStrategy/updateProgress', method: 'post', error: 'Error in Saving Progress' },
  },
  goals: {
    loadInitialState: { url: '/userStrategy/getGoals', method: 'get', error: 'Error in Loading Progress' },
    saveProgress: { url: '/userStrategy/updateToDo', method: 'post', error: 'Error in Saving Progress' },
  },
};
