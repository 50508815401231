import { createSlice } from '@reduxjs/toolkit';
import { preReqs } from './assistReducers';
const { name, initialState, thunks } = preReqs('strategy');
export const { addEditStrategy, loadInitialStateStrategy } = thunks;

export const strategySlice = createSlice({
  name: name,
  initialState: initialState,
  extraReducers: {
    [loadInitialStateStrategy.fulfilled]: (state, action) => {
      action = action.payload;
      if (action.status) {
        state[name] = action.data;
        state.isLoadedValues = true;
      }
    },

    [addEditStrategy.fulfilled]: (state, action) => {
      const { status, data, ...rest } = action.payload;
      if (status) {
        state[name] = data.strategy;
      }
    },
  },
});
