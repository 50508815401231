import { createSlice } from '@reduxjs/toolkit';
import { preReqs } from './assistReducers';
const { name, initialState, thunks } = preReqs('updateProgress');
export const { saveProgressUpdateProgress, loadInitialStateUpdateProgress } = thunks;

export const updateProgressSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setCurrentNode: (state, action) => void (state.currentNode = action.payload),
    setisLoadedValues: (state, action) => void (state.isLoadedValues = action.payload),
  },
  extraReducers: {
    [loadInitialStateUpdateProgress.fulfilled]: (state, { payload }) => {
      if (payload.status) {
        state[name] = payload.data;
        state.isLoadedValues = true;
      }
    },

    [saveProgressUpdateProgress.fulfilled]: (state, { payload }) => {
      const { status, data, ...rest } = payload;
      if (status) {
        state[name] = data;
      }
    },
  },
});

export const getThings = (state) => {
  if (!state[name].isLoadedValues) {
    return { state: state[name], loading: state.loading, error: state.error };
  }
  return { state: state[name], loading: state.loading, error: state.error };
};
